import React from 'react';

export default class DataList extends React.Component {
  state = { value: '' };
  static getDerivedStateFromProps = (props, state) =>
    props.value !== null && props.value !== state.value
      ? { value: props.value }
      : null;
  id = this.props.id ?? 'datalist-' + Math.floor(Math.random() * 10000);
  render = () => (
    <div className="datalist">
      <input
        list={this.id}
        type="text"
        value={this.state.value}
        placeholder={this.props.placeholder}
        onClick={this.props.click}
        onChange={this.props.change}
      />
      <ul id={this.id}>
        {this.props.children ??
          this.props.items?.map?.((value, i) => (
            <li
              onClick={() => {
                this.props.change({ target: { value } });
                this.setState({ value });
              }}
              key={i}
            >
              {value}
            </li>
          ))}
      </ul>
    </div>
  );
}
