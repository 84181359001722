import React from "react";
import ReactDOM from "react-dom";
import "style/css/pacta.css";
//import * as serviceWorker from "serviceWorker";
import App from "App";
import Error from "components/Error";

try {
  ReactDOM.render(
    <React.StrictMode>
      <React.Suspense fallback={<p>Loading…</p>}>
        <App />
      </React.Suspense>
    </React.StrictMode>,
    document.getElementById("root")
  );
} catch (error) {
  console.error(error);
  if (!error?.match?.(/metamask/i) && !error?.match?.(/bity/i))
    ReactDOM.render(
      <Error
        heading="Render Error"
        message={
          <>
            <p>Uncaught Error:</p>
            <p>{error.toString()}</p>
            <pre>{JSON.stringify(error, null, 4)}</pre>
          </>
        }
      />,
      document.getElementById("root")
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
