import 'leaflet/dist/leaflet.css';
import React from 'react';
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import { withTranslation } from 'react-i18next';
import Context from './Context';
import DataList from './components/DataList';
import { OpenStreetMapProvider } from 'leaflet-geosearch/dist/geosearch';

class Map extends React.Component {
  static contextType = Context;
  state = {
    results: null,
    timeout: null,
    locname: null,
    zoom: this.props.zoom ?? this.context.zoom,
  };

  provider = new OpenStreetMapProvider();

  componentDidMount = () =>
    navigator.geolocation.getCurrentPosition(
      position =>
        position.coords &&
        this.context.setPosition([
          position.coords.latitude,
          position.coords.longitude,
        ]),
    );

  componentWillUnmount = () => {
    clearTimeout(this.state.timeout);
  };

  getResults = e => {
    const query = e.target.value;
    clearTimeout(this.state.timeout);
    this.setState({
      locname: query,
      timeout: setTimeout(
        async () =>
          this.setState({
            results: await this.provider.search({ query }),
          }),
        500,
      ),
    });
  };

  render = () => (
    <div className={'map' + (this.props.set ? ' set' : '')}>
      <div className="leaflet-container">
        {this.context.ready ? (
          <LeafletMap
            center={this.context.position}
            zoom={this.state.zoom}
            onzoomend={e => {
              this.setState({ zoom: e.target.getZoom() });
              if (!this.props.zoom) this.context.setZoom(e.target.getZoom());
            }}
            onclick={this.props.click}
            onmoveend={e => this.context.setPosition(e.target.getCenter())}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution={false}
            />
            {this.props.children}
            {this.props.marker?.map((m, i) => (
              <Marker key={i} position={m.position} icon={m.icon}>
                {m.popup ? <Popup>{m.popup}</Popup> : <></>}
              </Marker>
            ))}
          </LeafletMap>
        ) : (
          <></>
        )}
      </div>
      <DataList
        value={this.state.locname ?? ''}
        placeholder={this.props.t('location.search')}
        click={this.getResults}
        change={this.getResults}
        items={this.state.results?.map(loc => (
          <li
            key={loc.raw.place_id}
            onClick={() => this.context.setPosition([loc.y, loc.x], loc.label)}
          >
            {(console.log(loc), loc.label)}
          </li>
        ))}
      />
    </div>
  );
}

export default withTranslation()(Map);
