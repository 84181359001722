import React from 'react';
import Map from './Map';
import Form from 'components/Form';
import { withTranslation } from 'react-i18next';
import Context from './Context';
import { divIcon } from 'leaflet';
import Axios from 'axios';
import {
  mdiAccountMultipleMinus,
  mdiAccountMultiplePlus,
  mdiMapMarker,
} from '@mdi/js';
import FileUpload from 'components/FileUpload';
import EditPanel from 'components/EditPanel';
import Select from 'components/Select';
import Option from 'components/Option';
import Success from 'components/Success';
import Error from 'components/Error';

class NewLocation extends React.Component {
  static contextType = Context;
  state = {
    position: this.context.position,
    name: '',
    search: '',
    groups: ['public'],
    x: null,
    y: null,
    address: '',
    fotos: [],
    available: [],
    description: '',
    unchangedName: true,
    types: [],
    type: '',
  };
  componentDidMount = async () => {
    navigator.geolocation.getCurrentPosition(
      position =>
        position.coords &&
        this.context.setPosition([
          position.coords.latitude,
          position.coords.longitude,
        ]),
    );
    this.search(this.state.search);
    const result = await this.context.apis.location.location_types();
    if (result.ok) {
      const types = result.obj;
      console.log({ types });
      this.setState({ types });
    }
  };
  viewBox = path => {
    var values = path.match(/(-?\d+(\.\d+)?)/gi);
    var max = Math.max.apply(Math, values);
    var min = Math.min.apply(Math, values);
    return 'viewBox="' + min + ' ' + min + ' ' + max + ' ' + max + '"';
  };
  search = async name => {
    try {
      const result = await (name.length
        ? this.context.apis.group.search_group({ name })
        : this.context.apis.group.search_all_group());
      if (result.ok)
        this.setState({ available: result.obj?.map?.(g => g.name) });
    } catch (e) {
      console.error(e);
    }
    if (name !== this.state.search) this.setState({ search: name });
  };
  create = async () => {
    try {
      const LocationCreateData = {
        name: this.state.name,
        description: this.state.description,
        address: this.state.address,
        x: this.state.position[0],
        y: this.state.position[1],
        type: this.state.type,
        groups: this.state.groups,
        fotos: this.state.fotos.map(foto => foto.content),
        admin: [this.context.user.name],
      };
      console.log(LocationCreateData);
      await this.context.apis.location.create_location(
        {},
        { requestBody: LocationCreateData },
      );
      this.context.setInfo(
        <Success
          heading={this.props.t('location.create-success')}
          message={this.props.t('location.create-succeded')}
        />,
      );
      this.context.setStatus(this.props.previous);
    } catch (e) {
      const message = e.response?.body?.message ?? e.message;
      this.context.setInfo(
        <Error
          heading={this.props.t('location.create-error')}
          message={
            <>
              <p>
                {e.status === 409
                  ? this.props.t('location.create-conflict')
                  : this.props.t('location.create-failed')}
              </p>
              {message ? <p>{message}</p> : <></>}
            </>
          }
          error={e}
        />,
      );
    }
  };
  render = () => (
    <Form
      heading={this.props.t('newlocation.title')}
      ok={this.create}
      cancel={() => this.context.setStatus(this.props.previous)}
      ready={
        !!(
          this.state.name.length &&
          this.state.description &&
          this.state.address &&
          this.state.groups.length &&
          this.state.type
        )
      }
    >
      <label>{this.props.t('newlocation.type')}</label>
      <Select
        name="location-type"
        selected={this.state.location}
        select={type => this.setState({ type })}
      >
        {this.state.types.map(type => (
          <Option heading={type} value={type} />
        ))}
      </Select>
      <label>{this.props.t('newlocation.define')}</label>
      <Map
        set
        zoom={18}
        click={e => {
          this.setState({ position: [e.latlng.lat, e.latlng.lng] });
          Axios.get(
            'https://nominatim.openstreetmap.org/reverse.php?lat=' +
              e.latlng.lat +
              '&lon=' +
              e.latlng.lng +
              '&zoom=18&format=jsonv2',
          )
            .then(({ status, data }) => {
              console.log({ status, data });
              if (status === 200) {
                let address =
                  data?.name ??
                  data?.address?.name ??
                  data?.address?.shop ??
                  data?.address?.craft ??
                  data?.address?.isolated_dwelling;
                const append = (b, c) => {
                  if (address && b) address += c + b;
                  else if (b) address = b;
                };
                append(data?.address?.road, ', ');
                append(data?.address?.house_number, ' ');
                append(data?.address?.postcode, ', ');
                append(data?.address?.city, ' ');
                append(data?.address?.country, ', ');
                this.setState({ address });
                if (this.state.unchangedName) this.setState({ name: address });
              }
            })
            .catch(console.error);
        }}
        marker={
          this.state.address
            ? [
                {
                  position: this.state.position,
                  icon: divIcon({
                    iconSize: [40, 40],
                    iconAnchor: [20, 40],
                    popupAnchor: [0, -40],
                    tooltipAnchor: [0, -40],
                    html:
                      '<svg ' +
                      this.viewBox(mdiMapMarker) +
                      '><path d="' +
                      mdiMapMarker +
                      '"></path></svg>',
                  }),
                  popup: 'Your Location',
                },
              ]
            : []
        }
      ></Map>
      <label>{this.props.t('newlocation.name')}</label>
      <input
        required
        type="text"
        value={this.state.name}
        onChange={e =>
          this.setState({ unchangedName: false, name: e.target.value })
        }
        placeholder={this.props.t('newlocation.name-placeholder')}
      ></input>
      <label>{this.props.t('newlocation.description')}</label>
      <textarea
        value={this.state.description}
        onChange={e => this.setState({ description: e.target.value })}
        placeholder={this.props.t('newlocation.description-placeholder')}
      ></textarea>
      <FileUpload update={fotos => this.setState({ fotos })}></FileUpload>
      <EditPanel
        addIcon={mdiAccountMultiplePlus}
        removeIcon={mdiAccountMultipleMinus}
        value={this.state.search}
        click={e => this.search(e.target.value)}
        change={e => this.search(e.target.value)}
        update={groups => this.setState({ groups })}
        items={this.state.available}
        placeholder={this.props.t('newlocation.group-placeholder')}
      >
        {this.state.groups}
      </EditPanel>
    </Form>
  );
}

export default withTranslation()(NewLocation);
