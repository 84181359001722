import React from "react";
import PropTypes from "prop-types";
export default class Success extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
  };
  render = () => (
    <section className="alert-box success">
      <h2>{this.props.heading}</h2>

      <div className="message">
        {typeof this.props.message === "string" ? (
          <p>{this.props.message}</p>
        ) : (
          this.props.message
        )}
      </div>
    </section>
  );
}
