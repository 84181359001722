import React from "react";
import PropTypes from "prop-types";

export default class Accordeon extends React.Component {
  static propTypes = {
    active: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };
  state = {
    active: 0,
  };
  componentDidMount = () => {
    let active =
      typeof this.props.active !== "undefined"
        ? this.props.active
        : this.state.active;
    this.calculateActive(active);
  };
  componentDidUpdate = () => this.calculateActive();
  calculateActive = (active = this.state.active) => {
    while (
      active < this.props.children.length &&
      this.props.children[active].props.hide
    )
      ++active;
    if (active === this.props.children.length) {
      active = 0; // restart
      while (
        active < this.props.children.length &&
        this.props.children[active].props.hide
      )
        ++active;
      if (active === this.props.children.length) active = 0;
    }
    if (active !== this.state.active) this.setState({ active });
  };
  render = () => (
    <div className="tabs">
      <div className="tabname">
        {this.props.children.map((v, i) =>
          i === this.state.active ? v.props.name : null
        )}
      </div>
      <div className="content">
        {this.props.children.find(
          (v, i) => i === this.state.active && !v.props.hide
        )}
      </div>
      <div className="tabbar">
        {this.props.children.map((v, i) =>
          v.props.hide ? null : (
            <button
              disabled={i === this.state.active}
              key={i}
              onClick={() => this.setState({ active: i })}
            >
              {v.props.name}
            </button>
          )
        )}
      </div>
    </div>
  );
}
