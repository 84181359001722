import React from "react";
import PropTypes from "prop-types";

export default class Close extends React.Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    closeText: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    active: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.keyFunction = this.keyFunction.bind(this);
  }

  keyFunction(event) {
    if (this.props.active === false) return;
    let key = event.which || event.keyCode;
    if (key === 27) this.props.close();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyFunction, false);
  }

  render = () => (
    <div className="closable">
      <button onClick={this.props.close} className="closebutton">
        {this.props.closeText ? this.props.closeText : "Close"}
      </button>
      {this.props.children}
    </div>
  );
}
