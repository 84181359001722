import React from "react";
import PropTypes from "prop-types";
export default class ButtonBar extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    right: PropTypes.bool,
  };
  render = () => (
    <div className={"buttonbar" + (this.props.right ? " rightbar" : "")}>
      {this.props.children}
    </div>
  );
}
