import 'leaflet/dist/leaflet.css';
import React from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { withTranslation } from 'react-i18next';
import Context from './Context';
import Map from './Map';
import Form from 'components/Form';

class Location extends React.Component {
  static contextType = Context;
  state = {
    results: null,
    timeout: null,
    locname: null,
  };

  provider = new OpenStreetMapProvider();

  componentWillUnmount = () => {
    clearTimeout(this.state.timeout);
  };

  getResults = e => {
    const query = e.target.value;
    clearTimeout(this.state.timeout);
    this.setState({
      locname: query,
      timeout: setTimeout(
        async () =>
          this.setState({
            results: await this.provider.search({ query }),
          }),
        500,
      ),
    });
  };

  render = () => (
    <Form heading={this.props.t('location.title')}>
      <Map />
    </Form>
  );
}

export default withTranslation()(Location);
