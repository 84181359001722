import React from 'react';
import Layout from 'components/Layout';
import Login from 'Login';
import i18n from 'i18n';
import i18next from 'i18next';
import { I18nextProvider, withTranslation } from 'react-i18next';
import Context from './Context';
import SwaggerClient from 'swagger-client';
import Location from './Location';
import Button from 'components/Button';
import {
  mdiAccount,
  mdiLogin,
  mdiLogout,
  mdiMapMarkerPlus,
  mdiAccountMultiplePlus,
} from '@mdi/js';
import Error from './components/Error';
import NewLocation from './NewLocation';
import NewGroup from './NewGroup';
import LandingPage from 'LandingPage';

class App extends React.Component {
  state = {
    options:
      'options' in localStorage
        ? JSON.parse(localStorage.options)
        : {
            language: i18next.language,
          },
    api: null,
    token: null,
    login: false,
    status: <Location />,
    ready: false,
    position: [47.37174, 8.54226], // Zürich
    zoom: 13,
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.resetPosition);
    this.resetPosition();
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      console.error({ msg, url, lineNo, columnNo, error });
      if (!msg.match(/metamask/i) && !msg.match(/bity/i))
        this.setInfo(
          <Error
            heading={msg}
            message={
              <>
                <dl>
                  <dt>{this.props.t('error.dt')}</dt>
                  <dd>{msg}</dd>
                  <dd>
                    <pre>{JSON.stringify(error, null, 4)}</pre>
                  </dd>
                  <dt>File:Line:Column</dt>
                  <dd>{url + ':' + lineNo + ':' + columnNo}</dd>
                </dl>
              </>
            }
          />,
        );
      return false;
    };
    if (!this.state.options.language)
      this.setState({ language: i18next.language });
    if (this.state.options.language !== i18next.language)
      i18next.changeLanguage(this.state.options.language);
    this.loadApi();
  };

  componentWillUnmount = () => {
    clearTimeout(this.state.timeoutInfo);
    clearTimeout(this.state.timeoutToken);
    clearTimeout(this.state.timeoutRenew);
    clearTimeout(this.state.timeoutPosition);
    clearTimeout(this.state.timeoutApi);
    window.removeEventListener('resize', this.resetPosition);
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      console.error({ msg, url, lineNo, columnNo, error });
      return false;
    };
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  http = request => {
    if (typeof this.state.token === 'string')
      request.headers['Authorization'] = 'Bearer ' + this.state.token;
    return SwaggerClient.http(request);
  };

  loadApi = () =>
    new SwaggerClient({
      http: this.http,
      url:
        (process.env.NODE_ENV === 'development'
          ? 'http://localhost:4000'
          : window.location.href.replace(/\/$/, '')) + '/api-json',
    })
      .then(api => this.setState({ api }))
      .catch(() =>
        this.setState({ timeoutApi: setTimeout(this.loadApi, 5000) }),
      );

  setPosition = (pos, locname = null) => {
    clearTimeout(this.state.timeoutPosition);
    this.setState({
      locname,
      position: pos,
      //ready: false,
      //timeoutPosition: setTimeout(() => this.setState({ ready: true }), 500),
    });
  };

  setZoom = zoom => {
    clearTimeout(this.state.timeoutPosition);
    this.setState({
      zoom,
      //ready: false,
      //timeoutPosition: setTimeout(() => this.setState({ ready: true }), 500),
    });
  };

  resetPosition = () => {
    this.setPosition(this.state.position);
    this.setState({
      ready: false,
      timeoutPosition: setTimeout(() => this.setState({ ready: true }), 500),
    });
  };

  setOption = async (key, value) => {
    if (key === 'language') await i18next.changeLanguage(value);
    localStorage.options = JSON.stringify({
      ...this.state.options,
      [key]: value,
    });
    this.setState(p => ({ options: { ...p.options, [key]: value } }));
  };

  renewToken = async () => {
    clearTimeout(this.state.timeoutRenew);
    console.log('renew access token');
    try {
      const result = await this.state.api.apis.user.renew();
      if (!result.ok || !result.obj?.access_token) throw Error;
      this.setToken(result.obj?.access_token);
    } catch (e) {
      console.error(e);
      this.setState({
        // in case of error, retry 10 times (remaining timeout is 1/4)
        timeoutRenew: this.state.timeoutToken
          ? setTimeout(this.renewToken, this.state.timeout / 40)
          : null,
      });
    }
  };

  setToken = token => {
    try {
      clearTimeout(this.state.timeoutToken);
      clearTimeout(this.state.timeoutRenew);
      let data = token?.split?.('.')?.[1];
      let user;
      if (data) user = JSON.parse(atob(data));
      let exp = new Date(user?.exp * 1000);
      const timeout = exp.getTime() - new Date().getTime();
      console.log({ exp, timeout });
      this.setState({
        user,
        token,
        timeout,
        login: false,
        timeoutRenew:
          token && exp
            ? setTimeout(
                this.renewToken,
                (timeout * 3) / 4, // renew when 3 / 4 of time expired
              )
            : null,
        timeoutToken:
          token && exp
            ? setTimeout(
                () =>
                  this.setState({
                    login: false,
                    token: null,
                    user: null,
                    timeoutToken: null,
                  }),
                exp.getTime() - new Date().getTime(),
              )
            : null,
      });
    } catch {}
  };

  setLogin = login => this.setState({ login });

  setInfo = info => {
    clearTimeout(this.state.timeoutInfo);
    this.setState({
      info,
      timeoutInfo: info
        ? setTimeout(
            () => this.setState({ info: null, timeoutInfo: null }),
            10000,
          )
        : null,
    });
  };

  setStatus = status => this.setState({ status: status ?? <Location /> });

  render = () => (
    <Context.Provider
      value={{
        options: this.state.options,
        apis: this.state.api?.apis,
        setToken: this.setToken,
        setLogin: this.setLogin,
        login: this.state.login,
        setInfo: this.setInfo,
        info: this.state.info,
        user: this.state.user,
        ready: this.state.ready,
        position: this.state.position,
        setPosition: this.setPosition,
        zoom: this.state.zoom,
        setZoom: this.setZoom,
        status: this.state.status,
        setStatus: this.setStatus,
      }}
    >
      <I18nextProvider i18n={i18n}>
        <Layout
          nav={
            <>
              <Button
                id="new-group"
                disabled={!this.state.user}
                onClick={() =>
                  this.setStatus(<NewGroup previous={this.state.status} />)
                }
                icon={mdiAccountMultiplePlus}
              />
              <Button
                id="new-location"
                disabled={!this.state.user}
                onClick={() =>
                  this.setStatus(<NewLocation previous={this.state.status} />)
                }
                icon={mdiMapMarkerPlus}
              />
              {this.state.user ? (
                <Button id="user" icon={mdiAccount}>
                  {this.state.user?.name}
                </Button>
              ) : (
                <></>
              )}
              <Button
                id="login"
                disabled={!this.state.api?.apis?.user}
                icon={this.state.token ? mdiLogout : mdiLogin}
                onClick={() =>
                  this.state.token
                    ? this.setToken(null)
                    : this.setState({ login: true })
                }
              />
            </>
          }
        >
          {this.state.error ? (
            <Error
              heading={this.props.t('error.boundary')}
              error={this.state.error}
            />
          ) : this.state.token ? (
            this.state.status
          ) : (
            <>
              <LandingPage></LandingPage>
              <Login></Login>
            </>
          )}
        </Layout>
      </I18nextProvider>
    </Context.Provider>
  );
}

export default withTranslation()(App);
