import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import { mdiImageMinus, mdiImagePlus } from '@mdi/js';
import Button from './Button';
import ButtonBar from './ButtonBar';

class FileUpload extends React.Component {
  state = { files: [], status: 0 };
  static propTypes = {
    update: PropTypes.func,
    /* children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]), */
  };

  drop = files => {
    this.setState({ status: this.state.status + files.length });
    files.forEach(file => {
      const reader = new FileReader();
      reader.onerror = () => this.setState({ status: this.state.status - 1 });
      reader.onabort = () => this.setState({ status: this.state.status - 1 });
      reader.onload = () => {
        file.content = reader.result;
        file.selected = false;
        if (this.props.update) this.props.update([...this.state.files, file]);
        this.setState({
          files: [...this.state.files, file],
          status: this.state.status - 1,
        });
      };
      reader.readAsBinaryString(file);
    });
  };

  render = () => (
    <div
      className={'fileupload ' + (this.state.status === 0 ? 'drop' : 'loading')}
    >
      <ButtonBar>
        <Dropzone accept="image/*" onDrop={this.drop} multiple>
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => (
            <div
              {...getRootProps({
                className: isDragActive
                  ? 'active'
                  : isDragAccept
                  ? 'accept'
                  : isDragReject
                  ? 'reject'
                  : 'unknown',
              })}
            >
              <Button icon={mdiImagePlus}>
                <input {...getInputProps()} />
                {this.props.t('fileupload.drop')}
              </Button>
            </div>
          )}
        </Dropzone>
        <Button
          disabled={!this.state.files.find(file => file.selected)}
          icon={mdiImageMinus}
          onClick={() =>
            this.setState({
              files: this.state.files.filter(file => !file.selected),
            })
          }
        >
          {this.props.t('fileupload.remove')}
        </Button>
      </ButtonBar>
      {this.state.files.length ? (
        <div className="preview">
          {this.state.files.map((file, i) => (
            <div
              className={'card img' + (file.selected ? ' selected' : '')}
              key={i}
              onClick={() => {
                file.selected = !file.selected;
                this.setState({ files: this.state.files });
              }}
            >
              <img
                alt={file.name}
                src={'data:' + file.type + ';base64,' + btoa(file.content)}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default withTranslation()(FileUpload);
