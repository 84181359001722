import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import video_mp4 from 'media/pacta.space.hd.en.available.mp4';
import video_webm from 'media/pacta.space.hd.en.available.webm';
import Container from 'components/Container';
import {
  mdiAccountGroup,
  mdiCloud,
  mdiEarth,
  mdiEmoticonCoolOutline,
  mdiGroup,
  mdiHeadLightbulbOutline,
  mdiHumanHandsup,
  mdiLightbulbOnOutline,
  mdiLockOutline,
  mdiOfficeBuilding,
  mdiRunFast,
  mdiServer,
  mdiShieldLockOutline,
} from '@mdi/js';
import Card from 'components/Card';
import Enjoy from 'style/images/enjoy.jpg';
import Grid from 'components/Grid';

class LandingPage extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
  };

  render = () => (
    <>
      <Container inverse>
        <h1>{this.props.t('landingpage.title')}</h1>
        <Card>{this.props.t('landingpage.about')}</Card>
      </Container>
      <video controls>
        <source src={video_mp4} type="video/mp4" />
        <source src={video_webm} type="video/webm" />
      </video>
      <Container>
        <h2>{this.props.t('landingpage.options')}</h2>
        <Grid size="4">
          <Card icon={mdiCloud} heading={this.props.t('landingpage.cloudhead')}>
            {this.props.t('landingpage.cloud')}
          </Card>
          <Card
            icon={mdiServer}
            heading={this.props.t('landingpage.premisehead')}
          >
            {this.props.t('landingpage.premise')}
          </Card>
          <Card
            icon={mdiEarth}
            heading={this.props.t('landingpage.publichead')}
          >
            {this.props.t('landingpage.public')}
          </Card>
          <Card
            icon={mdiGroup}
            heading={this.props.t('landingpage.privatehead')}
          >
            {this.props.t('landingpage.private')}
          </Card>
        </Grid>
      </Container>
      <div className="to-inverse"></div>
      <Container inverse>
        <h2>{this.props.t('landingpage.for')}</h2>
        <Grid size="3">
          <Card
            icon={mdiOfficeBuilding}
            heading={this.props.t('landingpage.companyhead')}
          >
            {this.props.t('landingpage.company')}
          </Card>
          <Card
            icon={mdiHumanHandsup}
            heading={this.props.t('landingpage.individualhead')}
          >
            {this.props.t('landingpage.individual')}
          </Card>
          <Card
            icon={mdiAccountGroup}
            heading={this.props.t('landingpage.associationhead')}
          >
            {this.props.t('landingpage.association')}
          </Card>
        </Grid>
      </Container>
      <div className="from-inverse"></div>
      <Container>
        <h2>{this.props.t('landingpage.features')}</h2>
        <Grid size="6">
          <Card
            type="mini max"
            icon={mdiEmoticonCoolOutline}
            heading={this.props.t('landingpage.easyhead')}
          >
            {this.props.t('landingpage.easy')}
          </Card>
          <Card
            type="mini max"
            icon={mdiHeadLightbulbOutline}
            heading={this.props.t('landingpage.smarthead')}
          >
            {this.props.t('landingpage.smart')}
          </Card>
          <Card
            type="mini max"
            icon={mdiLightbulbOnOutline}
            heading={this.props.t('landingpage.flexiblehead')}
          >
            {this.props.t('landingpage.flexible')}
          </Card>
          <Card
            type="mini max"
            icon={mdiShieldLockOutline}
            heading={this.props.t('landingpage.privacyhead')}
          >
            {this.props.t('landingpage.privacy')}
          </Card>
          <Card
            type="mini max"
            icon={mdiLockOutline}
            heading={this.props.t('landingpage.securityhead')}
          >
            {this.props.t('landingpage.security')}
          </Card>
          <Card
            type="mini max"
            icon={mdiRunFast}
            heading={this.props.t('landingpage.fasthead')}
          >
            {this.props.t('landingpage.fast')}
          </Card>
        </Grid>
      </Container>
      <div className="to-inverse"></div>
      <Container inverse>
        <div style={{ textAlign: 'center' }}>
          <img style={{ maxWidth: '80rem' }} src={Enjoy} alt="enjoy" />
        </div>{' '}
      </Container>
    </>
  );
}

export default withTranslation()(LandingPage);
