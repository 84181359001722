import React from "react";
import PropTypes from "prop-types";

export default class Item extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    hide: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };
  render = () => <div className="item">{this.props.children}</div>;
}
