import React from "react";
import PropTypes from "prop-types";

export default class ListPanel extends React.Component {
  static propTypes = {
    heading: PropTypes.string,
  };
  state = { selected: [] };
  static getDerivedStateFromProps = (props, state) => {
    return {
      selected: state.selected?.filter((v) => props.children?.includes?.(v)),
    };
  };
  toggle = (e) => {
    let selected = this.state.selected.includes(e.target.innerText)
      ? this.state.selected.filter((v) => v !== e.target.innerText)
      : [...this.state.selected, e.target.innerText];
    this.setState({ selected });
    if (this.props.change) this.props.change(selected);
  };
  render = () => (
    <div className="listpanel">
      {this.props.heading ? (
        <div class="heading">
          <h3>{this.props.heading}</h3>
        </div>
      ) : (
        <></>
      )}
      <ul className="items">
        {this.props.items ??
          this.props.children?.map?.((child) => (
            <li
              className={this.state.selected.includes(child) ? "selected" : ""}
              onClick={this.toggle}
              key={child}
            >
              {child}
            </li>
          ))}
      </ul>
    </div>
  );
}
