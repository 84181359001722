import React from 'react';
import DataList from './DataList';
import ListPanel from './ListPanel';
import Button from './Button';

export default class EditPanel extends React.Component {
  state = { value: '', selected: [] };

  constructor(props) {
    super(props);
    this.state.children = this.props.children ?? [];
  }
  static getDerivedStateFromProps = (props, state) => {
    const res = {};
    if (props.value !== null && props.value !== state.value)
      res.value = props.value;
    if (props.children !== null && props.children !== state.children)
      res.children = props.children;
    return res;
  };
  render = () => (
    <div className="editpanel" id={this.props.id}>
      <div className="select">
        <DataList
          id={this.props.id ? this.props.id + '-datalist' : null}
          placeholder={this.props.placeholder}
          click={this.props.click}
          change={e =>
            this.props.change?.(e) ?? this.setState({ value: e.target.value })
          }
          value={this.state.value}
          items={this.props.items}
        />
        <Button
          disabled={
            !this.props.items?.includes?.(this.state.value) ||
            this.state.children.includes(this.state.value)
          }
          onClick={() => {
            if (this.props.update)
              this.props.update([...this.state.children, this.state.value]);
            this.props.change?.({ target: { value: '' } }) ??
              this.setState({ value: '' });
            this.setState({
              children: [...this.state.children, this.state.value],
            });
          }}
          icon={this.props.addIcon}
        >
          {this.props.addText ?? <></>}
        </Button>
        <Button
          disabled={this.state.selected.length === 0}
          onClick={() => {
            const children = this.state.children.filter(
              v => !this.state.selected.includes(v),
            );
            const selected = this.state.selected.filter(v =>
              children.includes(v),
            );
            if (this.props.update) this.props.update(children);
            this.setState({ selected, children });
          }}
          icon={this.props.removeIcon}
        >
          {this.props.removeText ?? <></>}
        </Button>
      </div>
      <ListPanel change={selected => this.setState({ selected })}>
        {this.state.children}
      </ListPanel>
    </div>
  );
}
