import React from 'react';
import Form from 'components/Form';
import { withTranslation } from 'react-i18next';
import Context from './Context';
import EditPanel from './components/EditPanel';
import { mdiAccountPlus, mdiAccountMinus } from '@mdi/js';
import Error from 'components/Error';
import Success from 'components/Success';

class NewGroup extends React.Component {
  static contextType = Context;
  state = {
    name: '',
    description: '',
    search: '',
    groupuser: [],
    user: [],
  };
  search = async e => {
    const search = e.target.value;
    this.setState({ search });
    if (search.length > 2)
      try {
        const result = await this.context.apis.user.search_user({
          name: search,
        });
        if (result.ok) this.setState({ user: result.obj?.map?.(u => u.name) });
      } catch (e) {}
  };
  create = async () => {
    try {
      const GroupCreateData = {
        name: this.state.name,
        user: this.state.groupuser,
        admin: [this.context.user.name],
        description: this.state.description,
      };
      console.log(GroupCreateData);
      await this.context.apis.group.create_group(
        {},
        { requestBody: GroupCreateData },
      );
      this.context.setInfo(
        <Success
          heading={this.props.t('group.create-success')}
          message={this.props.t('group.create-succeded')}
        />,
      );
      this.context.setStatus(this.props.previous);
    } catch (e) {
      const message = e.response?.body?.message ?? e.message;
      this.context.setInfo(
        <Error
          heading={this.props.t('group.create-error')}
          message={
            <>
              <p>
                {e.status === 409
                  ? this.props.t('group.create-conflict')
                  : this.props.t('group.create-failed')}
              </p>
              {message ? <p>{message}</p> : <></>}
            </>
          }
          error={e}
        />,
      );
    }
  };
  render = () => (
    <Form
      heading={this.props.t('newgroup.title')}
      ok={this.create}
      cancel={() => this.context.setStatus(this.props.previous)}
      ready={this.state.name.length > 0}
    >
      <label>{this.props.t('newgroup.name')}</label>
      <input
        id="name"
        required
        type="text"
        value={this.state.name}
        onChange={e => this.setState({ name: e.target.value })}
        placeholder={this.props.t('newgroup.name-placeholder')}
      ></input>
      <label>{this.props.t('newgroup.description')}</label>
      <textarea
        id="description"
        value={this.state.description}
        onChange={e => this.setState({ description: e.target.value })}
        placeholder={this.props.t('newgroup.description-placeholder')}
      ></textarea>
      <label>{this.props.t('newgroup.userlist')}</label>
      <EditPanel
        id="groups"
        addIcon={mdiAccountPlus}
        removeIcon={mdiAccountMinus}
        value={this.state.search}
        click={this.search}
        change={this.search}
        update={groupuser => this.setState({ groupuser })}
        items={this.state.user}
        placeholder={this.props.t('newgroup.user-placeholder')}
      >
        {this.state.groupuser}
      </EditPanel>
    </Form>
  );
}

export default withTranslation()(NewGroup);
