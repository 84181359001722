import React from 'react';
import Tabs from 'components/Tabs';
import Item from 'components/Item';
import Form from 'components/Form';
import Error from 'components/Error';
import Success from 'components/Success';
import { withTranslation } from 'react-i18next';
import Context from './Context';
import lib from './lib';

class Login extends React.Component {
  static contextType = Context;
  state = {
    name: { value: '', valid: false },
    email: { value: '', valid: false },
    password: { value: '', valid: false },
    pwrepeat: { value: '', valid: false },
  };

  login = async () => {
    try {
      const UserLoginData = {
        name: this.state.name?.value,
        password: this.state.password?.value,
      };
      const result = await this.context.apis.user.login(
        {},
        { requestBody: UserLoginData },
      );
      if (!result.ok || !result.obj?.access_token) throw Error;
      console.log(result.obj?.access_token);
      this.context.setToken(result.obj?.access_token);
      console.log({ token: result.obj?.access_token });
      this.context.setInfo(
        <Success
          heading={this.props.t('login.success')}
          message={this.props.t('login.succeded')}
        />,
      );
    } catch (e) {
      console.error(e);
      const message = e.response?.body?.message ?? e.message;
      this.context.setInfo(
        <Error
          heading={this.props.t('login.error')}
          message={
            <>
              <p>
                {e.status === 430
                  ? this.props.t('login.forbidden')
                  : e.name === 'NetworkError'
                  ? this.props.t('error.network')
                  : this.props.t('login.failed')}
              </p>
              {message ? <p>{message}</p> : <></>}
            </>
          }
          error={e}
        />,
      );
    }
  };
  register = async () => {
    try {
      const UserCreateData = {
        name: this.state.name?.value,
        email: this.state.email?.value,
        password: this.state.password?.value,
      };
      await this.context.apis.user.create_user(
        {},
        { requestBody: UserCreateData },
      );
      this.context.setInfo(
        <Success
          heading={this.props.t('login.registration-success')}
          message={this.props.t('login.registration-succeded')}
        />,
      );
      this.login();
    } catch (e) {
      const message = e.response?.body?.message ?? e.message;
      this.context.setInfo(
        <Error
          heading={this.props.t('login.registration-error')}
          message={
            <>
              <p>
                {e.status === 409
                  ? this.props.t('login.registration-conflict')
                  : this.props.t('login.registration-failed')}
              </p>
              {message ? <p>{message}</p> : <></>}
            </>
          }
          error={e}
        />,
      );
    }
  };
  render = () =>
    this.context?.apis?.user ? (
      <Form show={this.context.login} nomargin>
        <Tabs>
          <Item name={this.props.t('login.login')}>
            <Form
              ok={this.login}
              okText={this.props.t('login.login')}
              cancel={() => this.context.setLogin(false)}
              ready={
                this.state.name?.valid &&
                this.state.password?.valid &&
                this.context.apis !== null
              }
            >
              <label>{this.props.t('login.username')}</label>
              <input
                name="name"
                required
                autoComplete="username"
                type="text"
                value={this.state.name?.value}
                onChange={e =>
                  this.setState({
                    name: {
                      value: e.target.value,
                      valid: e.target.validity.valid,
                    },
                  })
                }
                placeholder={this.props.t('login.username-placeholder')}
              />
              <label>{this.props.t('login.password')}</label>
              <input
                name="password"
                required
                autoComplete="current-password"
                type="password"
                value={this.state.password?.value}
                onChange={e =>
                  this.setState({
                    password: {
                      value: e.target.value,
                      valid: e.target.validity.valid,
                    },
                  })
                }
                placeholder={this.props.t('login.password-placeholder')}
              />
            </Form>
          </Item>
          <Item name={this.props.t('login.register')}>
            <Form
              ok={this.register}
              okText={this.props.t('login.register')}
              cancel={() => this.context.setLogin(false)}
              ready={
                this.state.name?.valid &&
                this.state.email?.valid &&
                this.state.password?.valid &&
                this.state.pwrepeat?.valid &&
                this.state.password?.value === this.state.pwrepeat?.value &&
                this.context.apis !== null
              }
            >
              <label>{this.props.t('login.username')}</label>
              <input
                name="name"
                autoComplete="username"
                required
                minLength={3}
                type="text"
                value={this.state.name?.value}
                onChange={e =>
                  this.setState({
                    name: {
                      value: e.target.value,
                      valid: e.target.validity.valid,
                    },
                  })
                }
                placeholder={this.props.t('login.username-placeholder')}
              />
              <label>{this.props.t('login.email')}</label>
              <input
                name="email"
                required
                type="email"
                value={this.state.email?.value}
                onChange={e =>
                  this.setState({
                    email: {
                      value: e.target.value,
                      valid: e.target.validity.valid,
                    },
                  })
                }
                placeholder={this.props.t('login.email-placeholder')}
              />
              <label>{this.props.t('login.password')}</label>
              <input
                name="password"
                autoComplete="new-password"
                required
                type="password"
                value={this.state.password?.value}
                onChange={e =>
                  this.setState({
                    pwrepeat: {
                      value: this.state.pwrepeat.value,
                      valid: this.state.pwrepeat.value === e.target.value,
                    },
                    password: {
                      value: e.target.value,
                      valid: e.target.validity.valid,
                    },
                  })
                }
                placeholder={this.props.t('login.password-placeholder')}
              />
              <label>{this.props.t('login.password-repeat')}</label>
              <input
                name="pwrepeat"
                required
                autoComplete="new-password"
                pattern={
                  '^' + lib.escapeRegExp(this.state.password?.value) + '$'
                }
                type="password"
                value={this.state.pwrepeat?.value}
                onChange={e =>
                  this.setState({
                    pwrepeat: {
                      value: e.target.value,
                      valid: e.target.validity.valid,
                    },
                  })
                }
                placeholder={this.props.t('login.password-repeat-placeholder')}
              />
            </Form>
          </Item>
        </Tabs>
      </Form>
    ) : (
      <></>
    );
}
export default withTranslation()(Login);
