import React from "react";
import PropTypes from "prop-types";

export default class Option extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.any.isRequired,
    selected: PropTypes.bool,
    select: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };
  render = () => (
    <div
      className={"option" + (this.props.selected ? " selected" : "")}
      onClick={this.props.select}
    >
      <label className="radio" htmlFor={this.props.name + this.props.value}>
        {this.props.heading}
        <input
          onChange={this.props.select}
          id={this.props.name + this.props.value}
          name={this.props.name}
          type="radio"
          checked={this.props.selected}
          value={this.props.value}
        />
        <span />
      </label>
      {React.Children.toArray(this.props.children).map((child, u) =>
        React.cloneElement(child, {
          disabled: !this.props.selected,
        })
      )}
    </div>
  );
}
