import React from "react";
import Error from "./Error";
import logo from "style/images/logo-white.svg";
import { name, version } from "../../package.json";
import Close from "./Close";
import Context from "../Context";

export default class Layout extends React.Component {
  static contextType = Context;
  state = {
    options: {
      language: "en",
    },
    error: null,
  };

  render = () => (
    <>
      <header lang={this.state.options.language} className="inverse">
        <div className="logo">
          <img src={logo} alt="" />
          <span>{name}</span>
        </div>
        <nav className="buttons">{this.props.nav}</nav>
      </header>
      <main lang={this.state.options.language}>
        {this.state.error ? (
          <Error {...this.state.error} />
        ) : (
          this.props.children
        )}
      </main>
      {this.context.info ? (
        <div className="status">
          <Close close={() => this.context.setInfo(null)}>
            {this.context.info}
          </Close>
        </div>
      ) : (
        <></>
      )}
      <footer lang={this.state.options.language}>
        <div>Version {version}</div>
        <a href="https://pacta.swiss" target="_blank" rel="noopener noreferrer">
          {"Provided by: "}
          <div className="logo">
            <img src={logo} alt="" />
            <span>Pacta AG</span>
          </div>
        </a>
      </footer>
    </>
  );
}
