import React from "react";
import PropTypes from "prop-types";

export default class Note extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
    inverse: PropTypes.bool,
  };
  render = () =>
    this.props.inverse ? (
      <div className="inverse">
        <div className="container"> {this.props.children} </div>
      </div>
    ) : (
      <div className="container"> {this.props.children} </div>
    );
}
